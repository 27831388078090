import {
  registerApplication,
  start,
  navigateToUrl,
  addErrorHandler,
  pathToActiveWhen,
  setBootstrapMaxTime,
  setMountMaxTime,
  getAppStatus,
  LOAD_ERROR,
} from 'single-spa'
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout'

import {
  login,
  forget,
  getCurrentUser,
  isInAuthIframe,
  authenticate,
  getIsAdfsUserByUrlParams,
} from '@je-pc/auth'
import { trackApplicationOnload } from '@je-pc/tracking'
import {
  GlobalConfigService,
  GlobalLanguageService,
  GlobalFeatureManagementService,
  GlobalTrackingService,
  GlobalCookieService,
  UsabillaService,
  Platform,
} from '@rmp/services'
import { setupGlobalStyles } from '@rmp/styleguide'
import { AuthClient, UserClient } from '@rmp/auth-client'

import { RoutesConfigManager } from './routes-config'

import { getRedirects } from './routes-config/redirects'
import { getRoutes } from './routes-config/routes'
import { TENANT, ENVIRONMENT, TEAM } from './constants'
import logger from './logger'

const SIGN_OUT_PATH = '/signout-jeconnect'
const LOGIN_PATH = '/login'
const BOOTSTRAP_MAX_TIME = 10000

async function startSpa(user) {
  const layoutData = {
    props: { user },
  }

  const routesConfig = new RoutesConfigManager(
    await getRoutes(layoutData),
    await getRedirects()
  ).getRoutesConfig()
  const routes = constructRoutes(routesConfig)

  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name)
    },
  })
  constructLayoutEngine({ routes, applications })
  applications.forEach(registerApplication)
  start({
    urlRerouteOnly: true,
  })
}

async function handleLogin(redirectUrl, loadApp) {
  try {
    await login()
  } catch (error) {
    logger.error(error, {
      message: 'handleLogin error',
      redirectUrl,
      parentIframe: isInAuthIframe(),
      location: new URL(location.href),
      loadApp: loadApp,
    })
  } finally {
    if (redirectUrl) navigateToUrl(redirectUrl)
  }
}

async function setupPlatform() {
  try {
    // eslint-disable-next-line no-console
    console.log('setupPlatform build')
    const initialLanguage = GlobalLanguageService.getInitialLanguage(TENANT)

    await GlobalConfigService.init({
      platform: Platform.je,
      country: TENANT,
      lang: initialLanguage,
      environment: ENVIRONMENT,
      tenant: TENANT,
      team: TEAM,
    })
    await AuthClient.init({
      configSubscriber: GlobalConfigService.subscribeToConfigs,
    })
    await GlobalCookieService.init()
    await GlobalTrackingService.init()
    await GlobalFeatureManagementService.init()
    UsabillaService.init()

    setupGlobalStyles()
    UserClient.subscribeToUserInfo(() => setupGlobalStyles())
  } catch (error) {
    logger.error(error)
  }
}

async function bootstrapApp() {
  const url = new URL(location.href)
  let loadApp = true

  if (url.pathname === SIGN_OUT_PATH) {
    loadApp = false
    forget()
  }

  if (isInAuthIframe()) {
    loadApp = false
    parent.postMessage(location.href, location.origin)
  }

  if (url.pathname === LOGIN_PATH) {
    const redirectUrl = loadApp ? url.searchParams.get('redirectUrl') : ''
    await handleLogin(redirectUrl, loadApp)
  }

  if (getIsAdfsUserByUrlParams()) {
    authenticate()
    loadApp = false
  }

  if (!loadApp) return

  setBootstrapMaxTime(BOOTSTRAP_MAX_TIME)
  setMountMaxTime(BOOTSTRAP_MAX_TIME)

  window.addEventListener(
    'single-spa:before-routing-event',
    ({ detail: { oldUrl, newUrl } }) => {
      if (new URL(newUrl).pathname === LOGIN_PATH) {
        logger.info('Login: single-spa event', {
          newUrl,
          oldUrl,
        })
        const redirectUrl = new URL(oldUrl).searchParams.get('redirectUrl')
        handleLogin(redirectUrl, loadApp)
      }
    }
  )

  window.addEventListener('single-spa:first-mount', () => {
    document.querySelector('#root-spinner')?.remove()
    document.querySelector('#root-error-container')?.remove()
  })

  await setupPlatform()
  const user = await getCurrentUser()
  trackApplicationOnload(user)

  await startSpa(user)

  addErrorHandler((error) => {
    // https://single-spa.js.org/docs/api/#handling-load_error-status-to-retry-module
    if (getAppStatus(error.appOrParcelName) === LOAD_ERROR) {
      System.delete(System.resolve(error.appOrParcelName))
    }
    logger.error(error)
  })
}

bootstrapApp()
