import { getBooleanFeatureFlag } from '../utils/featureFlags'
import {
  REDIRECT_FROM_LIVE_REPORTING,
  REDIRECT_FROM_PERFORMANCE,
  IS_TOP_RANK_ENABLED,
  IS_CHATBOT_ENABLED,
} from '../constants'

export const getRoutes = async (layoutData) => {
  const isGlobalFooterEnabled = getBooleanFeatureFlag(
    'global-footer-and-cookie'
  )

  const isUserManagementEnabled = getBooleanFeatureFlag(
    'enable_user_management'
  )

  return [
    {
      type: 'div',
      attrs: [
        {
          name: 'class',
          value: 'ph-app',
        },
      ],
      routes: [
        // navigation
        {
          type: 'nav',
          attrs: [
            {
              name: 'class',
              value: 'ph-header',
            },
          ],
          routes: [
            {
              type: 'application',
              name: '@rmp/navigation',
            },
          ],
        },

        // main
        {
          type: 'main',
          attrs: [
            {
              name: 'class',
              value: 'ph-main',
            },
          ],
          routes: [
            {
              type: 'application',
              name: '@rmp/app-global',
              props: {
                enablePages: false,
              },
            },
            {
              type: 'route',
              path: 'home',
              routes: [{ type: 'application', name: '@rmp/dashboard' }],
            },
            {
              type: 'route',
              path: 'analytics',
              routes: [{ type: 'application', name: '@rmp/analytics' }],
            },
            {
              type: 'route',
              path: 'partner-status',
              routes: [{ type: 'application', name: '@je-pc/partner-status' }],
            },
            {
              type: 'route',
              path: 'account',
              routes: [{ type: 'application', name: '@je-pc/account' }],
            },
            {
              type: 'route',
              path: 'account-settings',
              routes: [{ type: 'application', name: '@je-pc/account' }],
            },
            {
              type: 'route',
              path: 'reviews',
              routes: [{ type: 'application', name: '@je-pc/reviews' }],
            },
            {
              type: 'route',
              path: 'help',
              routes: [
                { type: 'application', name: '@je-pc/business-help' },
                { type: 'application', name: '@pha/ph-business-essentials' },
              ],
            },
            {
              type: 'route',
              path: 'live-reporting',
              routes: [
                {
                  type: 'application',
                  name: '@je-pc/live-reporting',
                  isEnabled: !REDIRECT_FROM_LIVE_REPORTING,
                },
              ],
            },
            {
              type: 'route',
              path: 'performance',
              routes: [
                {
                  type: 'application',
                  name: '@je-pc/performance',
                  isEnabled: !REDIRECT_FROM_PERFORMANCE,
                },
              ],
            },

            // orders-and-invoices
            {
              type: 'route',
              path: 'orders-and-invoices',
              routes: [
                {
                  type: 'route',
                  path: 'invoices',
                  routes: [{ type: 'application', name: '@je-pc/invoices' }],
                },
                {
                  type: 'route',
                  path: 'order-history',
                  routes: [
                    { type: 'application', name: '@je-pc/order-history' },
                  ],
                },
                {
                  type: 'route',
                  path: 'customer-refunds',
                  routes: [
                    { type: 'application', name: '@je-pc/restaurantclaimsweb' },
                  ],
                },
              ],
            },

            // menu
            {
              type: 'route',
              path: 'menu',
              routes: [
                {
                  type: 'route',
                  path: 'item-availability',
                  routes: [
                    { type: 'application', name: '@je-pc/item-availability' },
                  ],
                },
                {
                  type: 'route',
                  path: 'edit',
                  routes: [{ type: 'application', name: '@je-pc/menus' }],
                },
              ],
            },

            // settings
            {
              type: 'route',
              path: 'settings',
              routes: [
                {
                  type: 'route',
                  path: 'opening-hours',
                  routes: [
                    { type: 'application', name: '@je-pc/opening-hours' },
                  ],
                },
                {
                  type: 'route',
                  path: 'order-time',
                  routes: [{ type: 'application', name: '@je-pc/order-time' }],
                },
                {
                  type: 'route',
                  path: 'account',
                  routes: [{ type: 'application', name: '@je-pc/account' }],
                },
                {
                  type: 'route',
                  path: 'delivery-charges',
                  routes: [
                    { type: 'application', name: '@je-pc/delivery-charges' },
                  ],
                },
                {
                  type: 'route',
                  path: 'delivery-management',
                  routes: [
                    { type: 'application', name: '@je-pc/delivery-management' },
                  ],
                },
                {
                  type: 'route',
                  path: 'restaurant-details/media',
                  routes: [{ type: 'application', name: '@rmp/partner-media' }],
                },
                {
                  type: 'route',
                  path: 'restaurant-details',
                  routes: [
                    { type: 'application', name: '@je-pc/restaurant-details' },
                  ],
                },
              ],
            },

            // partner-chatbot
            {
              type: 'route',
              path: 'support/chatbot',
              routes: [
                {
                  type: 'application',
                  name: '@pha/partner-chatbot',
                  isEnabled: IS_CHATBOT_ENABLED,
                },
              ],
            },

            // marketing
            {
              type: 'route',
              path: 'marketing',
              routes: [
                {
                  type: 'route',
                  path: 'promoted-placement',
                  routes: [
                    {
                      type: 'application',
                      name: '@je-pc/promoted-placement',
                      props: { user: layoutData?.props?.user },
                    },
                  ],
                },
                {
                  type: 'route',
                  path: 'stamp-cards',
                  routes: [
                    {
                      type: 'application',
                      name: '@je-pc/marketing-stampcards',
                    },
                  ],
                },
                {
                  type: 'route',
                  path: 'offers',
                  routes: [
                    { type: 'application', name: '@je-pc/offers' },
                    { type: 'application', name: '@je-pc/marketing-offers' },
                  ],
                },
                {
                  type: 'route',
                  path: 'toprank',
                  routes: [
                    {
                      type: 'application',
                      name: '@je-pc/opacampaignmanagementweb',
                      isEnabled: IS_TOP_RANK_ENABLED,
                    },
                  ],
                },
              ],
            },

            // account-settings
            {
              type: 'route',
              path: 'ph-account-settings',
              routes: [
                {
                  type: 'application',
                  name: '@pha/ph-account-settings',
                },
              ],
            },

            {
              type: 'route',
              path: 'user-management',
              routes: [
                {
                  type: 'application',
                  name: '@pha/ph-user-management',
                  isEnabled: isUserManagementEnabled,
                },
              ],
            },

            {
              type: 'route',
              path: 'partner-invitation',
              routes: [
                {
                  type: 'application',
                  name: '@pha/um-partner-invitation',
                },
              ],
            },

            {
              type: 'route',
              default: true,
              routes: [
                {
                  type: 'application',
                  name: '@rmp/app-global-pages',
                  props: {
                    enableGlobals: false,
                    useNotFoundPage: true,
                  },
                },
              ],
            },
          ],
        },

        // footer
        {
          type: 'footer',
          ...(isGlobalFooterEnabled && {
            attrs: [
              {
                name: 'class',
                value: 'ph-footer',
              },
            ],
          }),
          routes: [
            {
              type: 'application',
              name: '@je-pc/footer',
              isEnabled: !isGlobalFooterEnabled,
            },
            {
              type: 'application',
              name: '@pha/footer',
              isEnabled: isGlobalFooterEnabled,
            },
          ],
        },
      ],
    },
  ]
}
